import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  Content,
  ImageCollection,
  FoodMenu,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class MarketPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessMenuModifierSet,
      allFishermanBusinessMenuModifier,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Market"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container market-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <Content container={true}>
                <Content.Markup isMarkdown={false}>
                  <Header as={null} content={""} />
                  <p>
                    In addition to the restaurant, we also have a seafood market
                    in Columbia, MO! Fresh deliveries from the Gulf of Mexico
                    arrive multiple times per week! Come try our fresh Blue
                    Crab, Snow Crab, Dungeness Crab and King Crab. Or enjoy both
                    peeled and unpeeled Shrimp, and other seafood varieties! We
                    have all the Cajun and Louisiana spices to make a perfect
                    seafood meal!
                  </p>
                  <div className={"market-contact-info"}>
                    <h3>Contact</h3>
                    <div>
                      <a href={"tel:+15734426562"} target={"_blank"}>
                        (573) 442-6562
                      </a>
                    </div>
                    <div>Open Wednesday to Sunday 10am -5pm</div>
                    <div>
                      <a
                        href={"mailto:cajuncrabhouse@gmail.com"}
                        target={"_blank"}
                      >
                        cajuncrabhouse@gmail.com
                      </a>
                    </div>
                  </div>
                  <p>
                    ALL SEAFOOD PRICES ARE SUBJECT TO CHANGE BASED ON MARKET
                    PRICE
                  </p>
                  <h3>We accept EBT at the market</h3>
                </Content.Markup>
              </Content>
              <ImageCollection
                images={[
                  "https://fisherman.gumlet.io/public/cajuncrabhouseseafoodrestaurant/image1.jpg",
                  "https://fisherman.gumlet.io/public/cajuncrabhouseseafoodrestaurant/image2.jpg",
                  "https://fisherman.gumlet.io/public/cajuncrabhouseseafoodrestaurant/image3.jpg",
                  "https://fisherman.gumlet.io/public/cajuncrabhouseseafoodrestaurant/image4.jpg",
                  "https://fisherman.gumlet.io/public/cajuncrabhouseseafoodrestaurant/image5.jpg",
                  "https://fisherman.gumlet.io/public/cajuncrabhouseseafoodrestaurant/image6.jpg",
                  "https://fisherman.gumlet.io/public/cajuncrabhouseseafoodrestaurant/image7.jpg",
                  "https://fisherman.gumlet.io/public/cajuncrabhouseseafoodrestaurant/image8.jpg",
                ]}
                as={"gallery"}
                centered={true}
                cutUnevenRows={false}
                disable={"none"}
                header={""}
                numberOfRowDisplayItems={4}
              />
            </Grid.Column>
            <Grid.Column width={"16"} textAlign={"left"}>
              <FoodMenu
                header={""}
                centerNavigation={true}
                categoryDisplayVariant={"HorizontalCardRow"}
                itemDisplayVariant={"HorizontalCard"}
                menu={{
                  schedules: allFishermanBusinessMenuSchedule.nodes,
                  categories: allFishermanBusinessMenuCategory.nodes,
                  items: allFishermanBusinessMenuItem.nodes,
                  modifierSets: allFishermanBusinessMenuModifierSet.nodes,
                  modifiers: allFishermanBusinessMenuModifier.nodes,
                }}
                business={{
                  id: fishermanBusiness._id,
                  type: fishermanBusiness.type,
                }}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Market" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessMenuSchedule(filter: { name: { eq: "Market" } }) {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCategory {
      nodes {
        items
        name
        description
        _id
      }
    }
    allFishermanBusinessMenuItem {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
      }
    }
    allFishermanBusinessMenuModifierSet {
      nodes {
        _id
        minAllowed
        modifiers
        name
      }
    }
    allFishermanBusinessMenuModifier {
      nodes {
        _id
        name
        price
      }
    }
  }
`;
